<template>
  <div class="header">
    <div
      class="nav"
      :class="{'avtive':navShow}"
    >
      <div
        class="header_left"
        v-show="!navShow"
      >
        <img
          src="../assets/logo-black.png"
          alt=""
          @click="listPage(0,1)"
        >
      </div>
      <div
        class="header_right"
        v-show="!navShow"
        @click="navShow=true"
      ></div>
      <p
        v-show="navShow"
        @click="navShow=false"
      ></p>
    </div>
    <ul
      class="nav_list"
      v-show="navShow"
    >
      <li
        v-for="(item,index) in navigation"
        :key="index"
        @click="listPage(index)"
      >{{ item }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navigation: ['关于我们', '业务介绍', '企业荣誉', '企业招聘', '人才培养', '发展历程', '合作伙伴', '联系我们'],
      navShow: false
    }
  },
  methods: {
    listPage(index, e) {
      if (e === 1) {
        this.$parent.slideTo(0)
      } else {
        this.$parent.slideTo(index + 1)
      }
      this.navShow = false
    }
  }
}
</script>

<style lang='scss'>
.header {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  width: 100%;
  .avtive {
    background-color: #fff;
  }
  .nav {
    height: 34px;
    padding: 10px 12px 0;
    overflow: hidden;
    .header_left {
      float: left;
      img {
        width: 72px;
        height: 24px;
      }
    }
    .header_right {
      width: 16px;
      height: 18px;
      margin-top: 4px;
      background: url(../assets/icon/headerRight.png) no-repeat;
      background-size: auto;
      float: right;
    }
    p {
      width: 20px;
      height: 34px;
      background: url(../assets/icon/eliminate.png) no-repeat;
      background-size: contain;
      float: right;
    }
  }

  .nav_list {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 18;
    margin-top: 44px;
    width: 100%;
    li {
      height: 78px;
      background-color: #c4c4c4;
      line-height: 78px;
      text-align: center;
      font-size: 18px;
    }
    li:nth-child(even) {
      background-color: #e2e2e2;
    }
  }
}
</style>