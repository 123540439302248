<template>
  <div class="headers">
    <headers />
    <div class="swiper-container home">
      <div class="swiper-wrapper ">
        <div class="swiper-slide homePage">
          <div class="home_content">
            <ul>
              <li class="home_title">
                <b>ZHANG </b>
                <span>WAN</span>
              </li>
              <li class="home_border">
                <span></span>
                <span></span>
                <span></span>
              </li>
              <li>掌玩网络</li>
              <li class="home_font">用技术驱动内容 用数字重构传播</li>
            </ul>
          </div>
        </div>
        <div class="swiper-slide">
          <div class="aboutUs">
            <h1>关于我们</h1>
            <div class="swiper-container aboutUs-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img
                    src="../assets/image/aboutUs.png"
                    alt=""
                  >
                  <p>「掌玩网络」成立于2014年，由互联网创业圈知名人物猫老大牵头成立，是一家综合型互联网新媒体矩阵平台，公司主营业务包含数字阅读、游戏发行、短剧、新媒体广告以及智能云服务等。2018年公司取得“国家高新技术企业”认定，至今公司全面拥抱市场挑战，并持续布局小说、游戏、短剧业务，继续努力在互联网浪潮中前行。</p>
                </div>
                <!-- <div class="swiper-slide">slider2</div>
              <div class="swiper-slide">slider3</div> -->
              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev swiper-button"></div>
              <div class="swiper-button-next swiper-button"></div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class=" aboutUs enterpriseHonor">
            <h1>业务介绍</h1>
            <div class="swiper-container aboutUs-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">

                  <img
                    src="../assets/image/businessIntroduction/1.jpg"
                    alt=""
                  >
                  <div class="title">短剧项目</div>
                  <p>掌玩于2022年末开始布局短剧业务，与抖音、腾讯、快手、百度等平台深度合作，凭借掌玩强大的广告投放能力，目前月流水稳定在亿级以上，成功在市场上推广多部优秀短剧，随着短视频内容多元化的发展，掌玩在业务模式上不断探索，开始创作自己的短剧集，致力于搭建国内顶尖的短剧平台。</p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/businessIntroduction/2.jpg"
                    alt=""
                  >

                  <div class="title">数字阅读</div>
                  <p>业务现在增涨迅猛，是包括番茄小说、阅文集团、七猫小说在内的各大小说平台核心代理，分发商。每年在抖音、腾讯、快手以及Facebook等平台广告消耗达到亿级，是行业内的头部企业，成为行业风向标，引领行业发展。</p>
                </div>

                <div class="swiper-slide">
                  <img
                    src="../assets/image/businessIntroduction/3.jpg"
                    alt=""
                  >

                  <div class="title">游戏发行</div>
                  <p>游戏业务于2021年立项，在三年时间内迅猛发展，成为掌玩的一大核心稳定业务。运营产品涵盖了角色扮演、卡牌策略、休闲竞技等类型，拥有传奇、三国、二次元、仙侠等垂直领域大“IP”资源。凭借强大的技术实力和专业的运营团队，发行了多款业内知名精品大作，并配合产品研发公司进行了多次产品调优，获得了业内和玩家的一致认可。
                    <br>
                    目前掌玩已成为全国知名游戏发行公司，打造出多款业内瞩目的精品游戏，产品月流水稳定至亿级以上。
                  </p>
                </div>

                <div class="swiper-slide">
                  <img
                    src="../assets/image/businessIntroduction/4.jpg"
                    alt=""
                  >

                  <div class="title">新媒体广告</div>
                  <p>「新媒体广告」是掌玩的起家业务，自有公众号涉及情感，汽车，旅游，生活等众多垂直领域，曾打造《一星期读一本书》《豪哥说车》《糗事大百科》等知名大号，截止目前公众号粉丝累计过亿，依然是行业头部企业，在行业内拥有极大的影响力。</p>
                </div>

              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev swiper-button"></div>
              <div class="swiper-button-next swiper-button"></div>
            </div>
          </div>
        </div>

        <div class="swiper-slide">
          <div class=" aboutUs enterpriseHonor enterprise_img">
            <h1>企业荣誉</h1>
            <div class="swiper-container aboutUs-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img
                    src="../assets/image/rongyuimg/hxj.png"
                    alt=""
                  >
                  <div class="title">恒星奖</div>
                  <p>荣获2024快手·磁力引擎“恒星奖”</p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/rongyuimg/xiangyang.png"
                    alt=""
                  >
                  <div class="title">最佳创新小游戏广告主</div>
                  <p>荣获2023年腾讯广告“年度贡献卓越广告主”与“最佳创新小游戏广告主”向阳奖 </p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/rongyuimg/yhzzj.png"
                    alt=""
                  >
                  <div class="title">年度最佳用户增长奖&小说年度女频突破</div>
                  <p>荣获2023腾讯广告 “年度最佳用户增长奖”与“小说年度女频突破”奖</p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/rongyuimg/zjhzhb.jpeg"
                    alt=""
                  >
                  <div class="title">最佳合作伙伴</div>
                  <p> 2022年被点众科技授予，“最佳合作伙伴”称号 </p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/rongyuimg/czwz.jpeg"
                    alt=""
                  >
                  <div class="title">充值王者</div>
                  <p> 2021年荣登腾讯广告自媒体王者榜，荣获“最强充值王”称号 </p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/rongyuimg/ry8.jpeg"
                    alt=""
                  >
                  <div class="title">消耗王者</div>
                  <p>2020年H1被腾讯广告授予“腾讯广告自媒体小说消耗王者”称号，并颁发特制奖杯 </p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/enterpriseHonor/2.png"
                    alt=""
                  >
                  <div class="title">测书王者</div>
                  <p>2020年H1被腾讯广告授予“腾讯广告自媒体小说测书王者”称号，并颁发特制奖杯 </p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/rongyuimg/ry12.jpeg"
                    alt=""
                  >
                  <div class="title">最佳战略合作伙伴</div>
                  <p>2020年9月9日，广州简知授予掌玩网络最佳战略合作伙伴称号</p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/rongyuimg/ry11.jpeg"
                    alt=""
                  >
                  <div class="title">优质合作伙伴</div>
                  <p>2019年度获得江苏文旭授予掌玩网络优质合作伙伴称号</p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/rongyuimg/ry3.png"
                    alt=""
                  >
                  <div class="title">计算机软件著作权</div>
                  <p>2016年至2019年，经国家版权局批准并颁发各类计算机软件著作权登记证书</p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/rongyuimg/ry7.jpeg"
                    alt=""
                  >
                  <div class="title">出版物经营许可证</div>
                  <p>2019年9月经杭州钱塘新区管理委员会批准并颁发《中华人民共和国出版物经营许可证》</p>
                </div>
                <div class="swiper-slide">
                  <img
                    src="../assets/image/rongyuimg/ry1.png"
                    alt=""
                  >
                  <div class="title">国家级高新技术企业</div>
                  <p>2018年11月被浙江省科学技术厅、浙江省财政厅、浙江省税务局共同评定为“国家级高新技术企业”，并颁发相关证书</p>
                </div>

              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev swiper-button"></div>
              <div class="swiper-button-next swiper-button"></div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class=" aboutUs enterpriseHonor invite">
            <h1>企业招聘</h1>
            <div class="swiper-container aboutUs-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item,index) in inviteList"
                  :key="index"
                  @click="inviteDetail(index)"
                >
                  <img
                    :src="_getImgUrl('invite/',index+1,'.jpg')"
                    alt=""
                  >
                  <p>{{item}}</p>
                </div>
              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev swiper-button"></div>
              <div class="swiper-button-next swiper-button"></div>
            </div>
          </div>
        </div>
        <div class="swiper-slide">
          <div class=" aboutUs enterpriseHonor enterprise_img">
            <h1>人才培养</h1>
            <div class="swiper-container aboutUs-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item,index) in talentCultivation"
                  :key="index"
                >
                  <img
                    :src="_getImgUrl('talentCultivation/',index+1,'.jpg')"
                    alt=""
                  >
                  <div class="title">{{ item.title }}</div>
                  <p>{{ item.content }} </p>
                </div>
                <!-- <div class="swiper-slide">
                  <img
                    src="../assets/image/talentCultivation/2.jpeg"
                    alt=""
                  >
                  <div class="title">网络文学</div>
                  <p>以公众号平台为依托，以小说内容为中心，完成目标粉丝群体留存率、活跃度、付费值、传播性的变现 </p>
                </div> -->

              </div>
              <!-- 如果需要导航按钮 -->
              <div class="swiper-button-prev swiper-button"></div>
              <div class="swiper-button-next swiper-button"></div>
            </div>
          </div>
        </div>
        <!-- v-show="!leadership" -->
        <div class="swiper-slide">
          <div class="aboutUs enterpriseHonor develop">
            <h1 v-text="fazhanSwiperActiveIndex < 12?'发展历程':'领导团队'"></h1>
            <div class="swiper-container fazhan-container">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item,index) in development"
                  :key="index"
                >
                  <img
                    :src="_getImgUrl('developmentHistory/',index+1,'.jpg')"
                    class="development"
                    alt=""
                  >
                  <p>{{item}}</p>
                </div>
                <div class="swiper-slide">
                  <img
                    :src="_getImgUrl('developmentHistory/',13,'.jpg')"
                    class="development"
                    style="height: auto;"
                  >
                  <div class="title boss">猫老大 <i>（董事长）</i></div>
                </div>
                <div class="swiper-slide">
                  <img
                    :src="_getImgUrl('developmentHistory/',14,'.jpg')"
                    class="development"
                    style="height: auto;"
                  >
                  <div class="title boss">罗涛 <i>（CEO）</i></div>
                </div>
              </div>
              <div class="swiper-button-prev swiper-button"></div>
              <div class="swiper-button-next swiper-button"></div>
            </div>

            <ul
              v-if="fazhanSwiperActiveIndex<12"
              class="schedule"
            >
              <li>
                <p :class="{'active':activeIndex===0}"></p>
                <b>{{ activityDate[0] }}</b>
              </li>
              <li>
                <p
                  class="schedule_line"
                  :class="{'active':activeIndex===1}"
                ></p>
                <b>{{ activityDate[1] }}</b>
              </li>
              <li>
                <p :class="{'active':activeIndex===2}"></p>
                <b>{{ activityDate[2] }}</b>
              </li>
            </ul>
          </div>
        </div>
        <!-- <div
          class="swiper-slide swiper-no-swiping"
          v-show="leadership"
        >
          <div class="aboutUs enterpriseHonor develop leaderships">
            <h1>领导团队</h1>
            <div class="swiper-container aboutUs-container">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <img
                    src="../assets/image/developmentHistory/13.jpg"
                    alt=""
                  >
                  <div class="title">猫老大 <i>（董事长）</i></div>
                  <p></p>
                </div>
              </div>
              <div
                class="swiper-button-prev swiper-button swiper_top"
                @click="swiperShow"
              ></div>
            </div>
          </div>
        </div> -->
        <div class="swiper-slide">
          <div class="aboutUs">
            <h1>合作伙伴</h1>
            <ul class="partner">
              <li
                v-for="index of 10"
                :key="index"
              >
                <img
                  :src="_getImgUrl('cooperativePartner/',index,'.png')"
                  alt=""
                >
              </li>
            </ul>
          </div>
        </div>
        <div class="swiper-slide">
          <ul class="connectUs">
            <li class="connectUs-title">
              <h1>联系我们</h1>
            </li>
            <li class="company-address">
              <b>公司地址：</b> <span>杭州市江干区金沙大道600号东部国际商务中心</span>
            </li>
            <li>
              <b>电子邮箱账号：</b> <span><a href="mailto:hr@zwwl.info">hr@zwwl.info</a></span>
            </li>
            <li>
              <b>联系电话：</b> <span><a href="tel://18072990871">18072990871（HR小海）</a></span>
            </li>
          </ul>
          <div id="container">
            <img src="../assets/image/map.png">
          </div>
        </div>
      </div>
      <!-- 如果需要分页器 -->
      <!-- <div class="swiper-pagination"></div> -->

      <!-- 如果需要滚动条 -->
      <!-- <div class="swiper-scrollbar"></div> -->
      <div
        class="home-bottom"
        v-show="page!=8"
      >
        <img
          v-if="page===0"
          src="../assets/image/bottomWhite.png"
        >
        <img
          v-else
          src="../assets/image/bottom.png"
        >
      </div>
    </div>
    <div
      class="popup_invite"
      v-show="inviteShow"
      @click="inviteShow=false"
    >
      <div class="popup_title">{{ position_title }}</div>
      <p
        v-for="(item,index) in position"
        :key="index"
      >{{ item }}</p>
      <br>
      <div class="popup_title">{{ takeOffice_title }}</div>
      <p
        v-for="(items,indexs) in takeOffice"
        :key="'info2-'+ indexs"
      >{{ items }}</p>
      <!-- <b>薪酬：{{money}}</b> -->
      <!-- <p>完善的五险一金、每周的下午茶、各类体育活动比赛（公司自己有篮球场和台球房）、员工体检、掌玩学院免费学习培训、年度旅游、部门团建、节假日公司内部主题活动、假日礼品、生日会、夜间企业打车服务等。</p> -->
    </div>
    <div
      class="popup_background"
      v-show="inviteShow"
      @click="inviteShow=false"
    ></div>
  </div>

</template>

<script>
import Swiper from 'swiper/dist/js/swiper.min.js'
import 'swiper/dist/css/swiper.css'
import headers from '@/components/header'
export default {
  components: {
    headers
  },
  data() {
    return {
      page: 0,
      activeIndex: 0,
      homeSwiper: null,
      homeSwiperActiveIndex: 0,
      fazhanSwiper: null,
      fazhanSwiperActiveIndex: 0,
      activeNum: 0,
      inviteShow: false,
      leadership: false,
      inviteList: ['管培生(24届、25届)', '短视频创意师', '信息流优化师', '游戏客服', '游戏用户运营', '投放助理'],
      position_title: '',
      position: ['1、挖掘、筛选符合市场需求的小说作品（偏重新媒体风格，男女频均可）；', '2、紧跟市场风向，根据市场和用户的特点，精修、调整作品内容；', '3、了解热门小说平台（如：阅文、阳光、掌中云、平治、掌阅、有书等），分析爆款作品 及用户人群；', '4、分析小说运营相关数据。'],
      takeOffice_title: '',
      takeOffice: ['1、专科及以上学历，1年以上小说责编、精修或写手经验；', '2、熟悉网络文学，有丰富的阅读量，阅读速度较快，对网文小说圈有极高的市场嗅觉；', '3、对各类小说内容有较强的判断及鉴赏识别能力，对用户需求及口味特点有准确理解和认知。'],
      money: '8000-16000',
      talentCultivation: [
        {
          title: '企业培训',
          content: '公司重视人才的可持续发展，定期开展北极星计划、行星计划、卫星计划、公开课等企业培训项目，不断提高在职员工的职业素质，致力于培养一专多能的“T”型人才。'
        },
        {
          title: '管培生计划',
          content: '管培生计划是让管培生经过内部重要业务部门的轮岗学习，为公司培养管理岗位人才的一项重要且长期的计划。'
        },
        {
          title: '掌成计划',
          content: '掌成计划是公司专门为内部重点员工开拓的除管培生外另一条储干培养通道，由内部选拔的优秀内训师和聘请的外训讲师进行授课，打造掌玩型管理人才。'
        },
        {
          title: '外出培训',
          content: '公司将不定期组织外出学习，从而提高员工的眼界、管理能力和理论知识，并能在实际工作中发挥明显作用。'
        },
        {
          title: '带教体系',
          content: '公司秉承“人为本”的价值观，搭建了完善的带教体系，提供一对一带教，使员工能够快速融入团队，适应工作环境。'
        }
      ],
      development: ['梦开始的地方，掌玩成立于和达城小区3楼', '掌玩第一次年会', '掌玩2016年年会晚宴', '掌玩乔迁至东部国际商务中心举办乔迁晚宴', '掌玩郑总和罗涛仅用时30分钟吃了一顿小龙虾就敲定了合作', '周年庆', '掌玩2018年掌玩年会', '王成和蒋秀杰加入掌玩，成为合伙人', '周年庆', '掌玩2019年年会', '掌玩2020年年会', '六周年庆'],
      activityDate: ['2014年6月', '2015年2月', '2016年2月'],
      indexNum: 0
    }
  },
  watch: {
    'homeSwiper.activeIndex': {
      handler: function (val) {
        this.page = val
        this.homeSwiperActiveIndex = val
      }
    },
    'fazhanSwiper.activeIndex': {
      handler: function (val) {
        this.page = val
        this.fazhanSwiperActiveIndex = val
      }
    }
  },
  mounted() {
    this.initializeSwiper()
    this.initializeSwipers()
    // this.baiMap()
  },
  methods: {
    // 获取图片地址
    _getImgUrl(name, pic_name, imgType) {
      // 这里路径必须是拼接出来的，不能写死成：return require(pic_name)
      return require('../assets/image/' + name + pic_name + imgType)
    },
    inviteDetail(index) {
      this.position = []
      this.takeOffice = []
      switch (Number(index)) {
        case 0:
          this.position_title = '任职要求:'
          this.position = ['1、重点大学985、211本科及以上，专业不限；', '2、热爱互联网、新媒体行业；', '3、良好的沟通协调能力，极强的快速学习能力，较强的责任心和进取心；', '4、能够承受工作压力和迎接挑战。']
          this.takeOffice_title = '培养计划:'
          this.takeOffice = ['第一年：项目深耕+一对一带教+通识技能培训；', '第二年：管理带教+专属定制培养计划；', '第三年：反哺0-1项目+新媒体新兴行业的实战演练。']
          // this.money = '8000-16000'
          break
        case 1:
          this.position_title = '我们的工作内容：'
          this.position = ['日常工作——根据优化师投放的短剧，寻找并剪辑适合推广该短剧的视频素材；', '日常创新——日常寻找对标的在投放热门视频、热点事件，汇总收集其卖点、拆解特性做裂变/创新；']
          this.takeOffice_title = '任职要求：:'
          this.takeOffice = ['短剧控——需要对小说、短剧感兴趣', '基础技能——熟练 ps 、pr等剪辑软件，会抖音短视频剪辑、素材剪辑、包装、配音等技能；', '对热点有高敏感度——有网感、抖音重度用户，追踪各类平台热门视频并捕捉其亮点；', '音乐控——熟知抖音、 B 站平台最近流行的音效、配乐、流行内容；', '专业对口——广告学、设计、数字媒体专业或重度书虫优先考虑。', '可接受优秀的应届毕业生，欢迎投递']
          // this.money = '8000-16000'
          break
        case 2:
          this.position_title = '岗位职责：'
          this.position = ['1.主要负责短剧、小说、游戏其中一个产品在抖音、快手、腾讯渠道上的广告投放计划制定以及日常投放。', '2.每日分析和解读数据，分析市场需求，不断优化广告效果，提高回报率。', '3.监控和研究竞争对手相关策略，并围绕优化提出合理的调整建议。', '4.对广告投放的素材，有提出优化和修改的能力，进行分析，并提供优化策略，使广告投放效果不断优化；完成既定的广告投放目标和效果。', '5.监控和分析广告账户，阶段性总结，保证广告的良性投放，提高广告效果，分析广告文案，实施帐户优化操作。']
          this.takeOffice_title = '任职要求：'
          this.takeOffice = ['1.熟悉各种流量平台，精通一样大平台投放经验（如头条系，腾讯系，ＭＰ广告），至少一年以上工作经验；', '2.有短剧、小说、游戏投放的相关经验；', '3.大专以上学历，工作认真细致，具备较强的数据分析能力和明确的投放理念；']
          // this.money = '8000-16000'
          break
        case 3:
          this.position_title = '岗位职责：'
          this.position = ['1、深度了解游戏用户心理，能对玩家的各种诉求提供合理的解决方案。', '2、负责与游戏VIP玩家建立联系，给VIP玩家提供优质服务，促进玩家的充值流水;', '3、为大R玩家提供专属服务，长期保持良好沟通，提高活跃降低流失;', '4、收集和反馈VIP玩家意见、总结和分析VIP玩家数据并及时汇报。']
          this.takeOffice_title = '任职要求：'
          this.takeOffice = ['1、了解传奇类游戏，销售经验优先考虑，有游戏行业营销经验最佳；', '2、执行力强，严格执行领导安排的每项工作；', '3、专科以上学历，熟练掌握Office办公软件；']
          // this.money = '8000-16000'
          break
        case 4:
          this.position_title = '岗位职责：'
          this.position = ['1. 了解传奇类、三国类、仙侠类游戏玩法，入驻具体游戏区服，以合理的方式带动游戏气氛，促进服务器内游戏生态活跃和区服流水。', '2. 建立与游戏玩家良性互动关系进行玩家管理，解决玩家的问题，避免玩家流失，提升用户粘性。', '3. 对玩家进行游戏带教，辅助玩家正确进行游戏，保证用户玩家的游戏体验。', '4. 收集和反馈玩家意见、总结和分析各类玩家数据及时汇报。', '5. 关注游戏线上的运行情况，遇到突发问题及时反应和解决。']
          this.takeOffice_title = '任职要求：'
          this.takeOffice = ['1. 专业不限，男女不限，年龄20-35岁，大专以上学历，有手游，网游，页游经验者优先。', '2. 对移动游戏感兴趣，思维敏捷、善于沟通，应变能力强；', '3. 具备良好的团队协作、组织协调能力及适当的分析能力；', '4. 有清晰且明确的思维及语言表达能力，熟练掌握OFFICE等办公类软件。']
          // this.money = '8000-16000'
          break
        case 5:
          this.position_title = '岗位职责：'
          this.position = ['1、按照小说、短剧的产品属性、用户需求及广告文案，寻找并创作适合推广的视频广告，展开广告投放工作；', '2、协助投手输出适合的图文、视频素材；', '3、学习头条、抖音、广点通投放后台操作，掌握投放逻辑、投放思路；']
          this.takeOffice_title = '任职要求：'
          this.takeOffice = ['1、有过抖音、快手、腾讯等渠道的投放经验；', '2、熟悉抖音、快手、微博、微视等短视频平台玩法、套路；', '3、肯吃苦，肯学习，有上进心；']
          // this.money = '8000-16000'
          break
        default:
          break
      }
      this.inviteShow = true
    },
    baiMap() {
      var map = new BMapGL.Map('container') // 创建地图实例
      var point = new BMapGL.Point(120.330192, 30.317006)
      var scaleCtrl = new BMapGL.ScaleControl() // 添加比例尺控件
      map.addControl(scaleCtrl)
      var zoomCtrl = new BMapGL.ZoomControl({ anchor: BMAP_ANCHOR_TOP_LEFT }) // 添加缩放控件
      map.addControl(zoomCtrl)
      var marker = new BMapGL.Marker(point) // 创建标注
      map.addOverlay(marker)
      map.centerAndZoom(point, 15)
      var content = '杭州掌玩网络'
      var label = new BMapGL.Label(content, {
        // 创建文本标注
        position: point,
        offset: new BMapGL.Size(16, -18)
      })
      var opts = {
        position: point, // 指定文本标注所在的地理位置
        offset: new BMapGL.Size(30, -30) //设置文本偏移量
      }
      map.addOverlay(label)
      map.enableScrollWheelZoom(true) //开启鼠标滚轮缩放
    },
    initializeSwiper(pageSize) {
      const that = this
      that.leadership = false
      var mySwiper = new Swiper('.home', {
        onTransitionEnd: function (mySwiper) {
          // that.page = mySwiper.activeIndex
        },
        direction: 'vertical',
        loop: false
        // initialSlide: this.page,
        // initialSlide: 6
        // observeParents: true, //修改swiper的父元素时，自动初始化swiper

        // observer: true //修改swiper自己或子元素时，自动初始化swiper
      })
      this.homeSwiper = mySwiper
      // mySwiper.slideTo(pageSize, 2000, false)
      // that.page = pageSize
    },
    slideTo(page) {
      this.homeSwiper.slideTo(page, 2000, false)
    },
    swiperShow() {
      this.leadership = false
      this.indexNum = 0
    },
    initializeSwipers() {
      const that = this
      var mySwiper1 = new Swiper('.aboutUs-container', {
        direction: 'horizontal',
        loop: false,
        observer: true,
        // autoHeight: true,
        // initialSlide: 4,
        //observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // initialSlide: this.page
        // 如果需要分页器
        // pagination: '.swiper-pagination',

        // 如果需要前进后退按钮
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev'
      })

      var fazhan = new Swiper('.fazhan-container', {
        direction: 'horizontal',
        loop: false,
        observer: true,
        // autoHeight: true,
        // initialSlide: 12,
        //observeParents: true, //修改swiper的父元素时，自动初始化swiper
        // initialSlide: this.page
        // 如果需要分页器
        // pagination: '.swiper-pagination',

        // 如果需要前进后退按钮
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        onTransitionEnd: (mySwiper) => {
          console.log(mySwiper.activeIndex)
          // if (mySwiper.activeIndex > 2) {
          const activeIndex = mySwiper.activeIndex + 4

          if (activeIndex % 3 === 0) {
            that.activeIndex = 2
          } else if (activeIndex % 3 === 2) {
            that.activeIndex = 1
          } else {
            that.activeIndex = 0
          }
          if (mySwiper.activeIndex >= 12) {
            that.activeIndex = 2
          }
          console.log(mySwiper.activeIndex, activeIndex, that.activeIndex)
          // } else {
          //   that.activeIndex = mySwiper.activeIndex
          // }

          if (mySwiper.activeIndex <= 2) {
            that.activityDate = ['2014年6月', '2015年2月', '2016年2月']
          }
          if (mySwiper.activeIndex >= 3 && mySwiper.activeIndex <= 5) {
            that.activityDate = ['2017年4月', '2017年6月', '2017年10月']
          }
          if (mySwiper.activeIndex >= 6 && mySwiper.activeIndex <= 8) {
            that.activityDate = ['2018年2月', '2018年5月', '2018年6月']
          }
          if (mySwiper.activeIndex >= 9) {
            that.activityDate = ['2019年2月', '2020年2月', '2020年8月']
          }
        }
      })
      this.fazhanSwiper = fazhan
    }
  }
}
</script>

<style lang='scss'>
.headers {
  width: 100%;
  height: 100%;
  .swiper-container {
    width: 100%;
    height: 100%;
    .home-bottom {
      position: absolute;
      bottom: 10px;
      left: 50%;
      z-index: 1;
      transform: translateX(-50%);
      animation: move 1s ease infinite;
    }
    @keyframes move {
      0% {
        padding-bottom: 0;
        opacity: 1;
      }
      30% {
        opacity: 0.1;
        padding-bottom: 10px;
      }
      60% {
        opacity: 1;
        padding-bottom: -10px;
      }
      100% {
        padding-bottom: 0;
        opacity: 1;
      }
    }
    .homePage {
      height: 100%;
      background: url(../assets/image/home.png) no-repeat;
      background-size: cover;
      .home_content {
        width: 270px;
        height: 270px;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 6px;
        overflow: hidden;
        text-align: center;
        background-color: rgba($color: #000000, $alpha: 0.4);
        margin: 0 auto;
        color: #fff;
        font-size: 20px;
        .home_title {
          font-size: 18px;
          margin-top: 59px;
          letter-spacing: 4px;
          span {
            color: #df4605;
            font-weight: bold;
          }
        }
        .home_border {
          span:nth-child(1) {
            border: 1px solid #df4605;
          }
          span {
            display: inline-block;
            width: 20px;
            height: 20px;
            border: 1px solid #fff;
            transform: rotate(45deg);
            margin: 19px 8px;
          }
        }
        .home_font {
          font-size: 16px;
          margin-top: 20px;
        }
      }
    }

    .aboutUs {
      margin-top: 34px;
      h1 {
        font-size: 18px;
        text-align: center;
        letter-spacing: 2px;
      }
      img {
        width: 260px;
      }
      .aboutUs-container {
        // height: 260px;
        position: relative;
        .swiper-button {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 123px;
        }
        .swiper-button-prev {
          background-image: url(../assets/image/left.png);
          background-size: contain;
          margin-left: 12px;
        }
        .swiper-button-next {
          background-image: url(../assets/image/right.png);
          background-size: contain;
          margin-right: 12px;
        }
        .swiper-slide {
          margin-top: 30px;
          text-align: center;
          overflow: hidden;
        }
        p {
          font-size: 14px;
          width: 260px;
          margin: 30px auto 0;
          line-height: 24px;
          text-align: left;
        }
      }
      .fazhan-container {
        // height: 260px;
        overflow: initial !important;
        position: relative;
        .swiper-button {
          width: 16px;
          height: 16px;
          position: absolute;
          top: 123px;
        }
        .swiper-button-prev {
          background-image: url(../assets/image/left.png);
          background-size: contain;
          margin-left: 12px;
        }
        .swiper-button-next {
          background-image: url(../assets/image/right.png);
          background-size: contain;
          margin-right: 12px;
        }
        .swiper-slide {
          position: relative;
          margin-top: 30px;
          text-align: center;
          .development {
            height: 187px;
            // height: auto;
          }
          p {
            text-align: center;
          }
        }
        p {
          font-size: 14px;
          width: 260px;
          margin: 30px auto 0;
          line-height: 24px;
          text-align: left;
        }
      }
    }
    .enterpriseHonor {
      .title {
        color: #df4605;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        padding-top: 30px;
        padding-bottom: 30px;
        // position: absolute;
        // bottom: -60px;
        width: 100%;
        &.boss {
          padding-top: 0;
          padding-bottom: 0;
          position: absolute;
          bottom: -60px;
        }
      }
      .aboutUs-container {
        img {
          height: 164px;
        }
        .swiper-button {
          top: 137px;
        }
      }
    }
    .enterprise_img {
      .aboutUs-container {
        img {
          height: 195px;
        }
      }
    }
    .invite {
      .aboutUs-container {
        p {
          text-align: center;
        }
        img {
          height: 257px;
        }
        .swiper-button {
          top: 167px;
        }
      }
    }
    .develop {
      .aboutUs-container {
        p {
          text-align: center;
        }
        .development {
          height: 187px;
          // height: auto;
        }
        // .boss_img {
        //   width: 180px;
        //   height: 269px;
        // }
        .swiper-button {
          top: 140px;
        }
      }
      .schedule {
        width: 375px;
        margin: 50px auto 0;
        overflow: hidden;
        li {
          float: left;
          margin-left: 38px;
          font-size: 16px;
          p {
            width: 14px;
            height: 14px;
            border-radius: 50%;
            border: 1px solid #999999;
            text-align: center;
            margin-bottom: 20px;
            margin-left: 29px;
          }
          .schedule_line {
            position: relative;
          }
          .schedule_line::before {
            content: '';
            width: 101px;
            height: 1px;
            background-color: #979797;
            position: absolute;
            right: 14px;
            top: 7px;
          }
          .schedule_line::after {
            content: '';
            width: 101px;
            height: 1px;
            background-color: #979797;
            position: absolute;
            left: 14px;
            top: 7px;
          }
          .active {
            border: 1px solid #ea5303;
            background-color: #ea5303;
          }
        }
      }
    }
    .connectUs {
      margin-top: 45px;
      padding: 0 12px;
      li {
        border-bottom: 1px solid #e2e2e2;
        font-size: 14px;
        color: #333333;
        padding: 28px 0;
        h1 {
          text-align: center;
          font-size: 18px;
        }
        b {
          color: #ea5303;
        }
      }
      .company-address {
        padding: 17px 0 19px;
        display: flex;
        align-items: center;
        b {
          vertical-align: top;
          flex: 0 1 auto;
          word-break: keep-all;
        }
        span {
          display: inline-block;
          line-height: 18px;
        }
      }
      .connectUs-title {
        padding: 0 0 20px;
      }
    }
    .partner {
      width: 375px;
      margin: 0 auto;
      overflow: hidden;
      padding: 20px 12px;
      li {
        float: left;
        width: 150px;
        height: 75px;
        padding: 5px 8px;
        border-radius: 10px;
        background-color: #fff;
        text-align: center;
        line-height: 95px;
        box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.2);
        margin-bottom: 20px;
      }
      li:nth-child(odd) {
        margin-right: 19px;
      }
      li:nth-child(2) {
        img {
          margin-top: 7px;
        }
      }
      li:nth-child(3) {
        img {
          margin-top: 10px;
        }
      }
      li:nth-child(4) {
        img {
          margin-top: 18px;
        }
      }
      li:nth-child(6) {
        img {
          margin-top: 12px;
        }
      }
      li:nth-child(8) {
        img {
          margin-top: 8px;
        }
      }
      li:nth-child(9) {
        img {
          margin-top: 10px;
        }
      }
    }
    .leaderships {
      .aboutUs-container {
        .swiper_top {
          top: 180px;
        }
        img {
          width: 180px;
          height: 269px;
        }
        .title {
          i {
            color: #333;
            font-size: 15px;
            font-weight: 500;
          }
        }
      }
    }
    #container {
      width: 351px;
      height: 250px;
      margin: 48px auto 0;
      .BMap_stdMpZoom {
        top: 11px !important;
      }
      .anchorBL {
        img {
          margin-top: 5px !important;
        }
      }
    }
  }
  .popup_invite {
    padding: 20px;
    width: 305px;
    background-color: #fff;
    position: absolute;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    top: 60px;
    left: 50%;
    z-index: 14;
    transform: translateX(-50%);
    line-height: 24px;
    b {
      color: #f20606;
    }
  }
  .popup_background {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 13;
    background-color: rgba($color: #000000, $alpha: 0.4);
  }
}
</style>