import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'header',
    component: () => import('@/views/home')
  },
  {
    path: '/header',
    name: 'Header',
    component: () => import('@/components/header')
  }
  // {
  //   path: '/',
  //   name: 'Home',
  //   component: () => import('../views/Home2')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
